<template>
    <div id="files" class="header_space">
        <div v-if="isLoading" class="loading_image">
            <h1 class="main_font">
                {{ loadingText }}
            </h1>
            <img src="../assets/loading.gif" />
        </div>

        <span v-else>
            <table id="files_table">
                <tr v-for="(item, index) in s3Objects" :key="item.key">
                    <td
                        :class="
                            isFileSelected(index)
                                ? 'files_row_active'
                                : 'files_row_hover'
                        "
                        @click="selectFile(index)"
                    >
                        <h1 class="file_name main_font">{{ item.key }}</h1>
                        <table v-if="isFileSelected(index)" width="100%">
                            <tr>
                                <table width="100%">
                                    <tr>
                                        <td align="right">
                                            <p class="main_font_bold">
                                                file name:
                                            </p>
                                        </td>
                                        <td align="left">
                                            <p class="main_font">
                                                {{ item.key }}
                                            </p>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td align="right">
                                            <p class="main_font_bold">
                                                last modified:
                                            </p>
                                        </td>
                                        <td align="left">
                                            <p class="main_font">
                                                {{ item.lastModified }}
                                            </p>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td align="right">
                                            <p class="main_font_bold">
                                                size:
                                            </p>
                                        </td>
                                        <td align="left">
                                            <p class="main_font">
                                                {{ item.size }} byte
                                            </p>
                                        </td>
                                    </tr>
                                </table>
                            </tr>

                            <tr>
                                <td>
                                    <button
                                        class="files_button main_font"
                                        :disabled="!isGlb(item.key)"
                                        @click="render(item.key)"
                                    >
                                        Render
                                    </button>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <button
                                        class="files_button main_font"
                                        :disabled="!isIfc(item.key)"
                                        @click="calculateCompliance(item.key)"
                                    >
                                        Calculate Compliance
                                    </button>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <button
                                        class="files_button main_font"
                                        :disabled="!isIfc(item.key)"
                                        @click="calculateSemantics(item.key)"
                                    >
                                        Calculate Semantics
                                    </button>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <button
                                        class="files_button main_font"
                                        :disabled="!isIfc(item.key)"
                                        @click="convertIfc(item.key)"
                                    >
                                        Convert IFC
                                    </button>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <button
                                        class="files_button main_font"
                                        @click="deleteS3Object(item.key)"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </span>
    </div>
</template>

<script>
import invokeS3Delete from '../util/api/invokeS3Delete';
import invokeS3List from '../util/api/invokeS3List';
import invokeS3ToEfs from '../util/api/invokeS3ToEfs';
import invokeEfsToS3 from '../util/api/invokeEfsToS3';
import invokeIfcConvert from '../util/api/invokeIfcConvert';
import readCookie from '../util/readCookie';

import createLogger from '../util/createLogger';
const logger = createLogger('Files.vue');

async function buildTable() {
    logger.info('buildTable');

    this.loadingText = 'loading files...';
    this.isLoading = true;

    let objectList = await invokeS3List();
    if (!objectList) {
        logger.info('object list was falsy, setting empty array');
        objectList = [];
    }

    logger.debug(JSON.stringify(objectList, null, 4));

    this.s3Objects = objectList;
    this.isLoading = false;
}

function selectFile(index) {
    logger.info(`selectFile ${index}`);

    if (this.preventSelectOnce) {
        this.preventSelectOnce = false;
        return;
    }

    this.selectedFile =
        this.selectedFile.index === index
            ? { selected: false }
            : { index, selected: true };
}

function isFileSelected(index) {
    return (
        this.selectedFile.index === index && this.selectedFile.selected === true
    );
}

async function render(objectKey) {
    logger.info(`rendering ${objectKey}`);
    this.preventSelectOnce = true;

    logger.debug('router');
    logger.debug(this.router);
    logger.debug(this.$router);
    this.$router.push({ name: 'Render', params: { objectKey } });
}

async function calculateCompliance(objectKey) {
    logger.info(`calculateCompliance ${objectKey}`);

    this.$router.push({ name: 'Compliance', params: { objectKey } });
}

async function calculateSemantics(objectKey) {
    logger.info(`calculateSemantics ${objectKey}`);

    this.$router.push({ name: 'Semantics', params: { objectKey } });
}

async function convertIfc(objectKey) {
    logger.info(`convertIfc ${objectKey}`);
    this.preventSelectOnce = true;

    const idToken = readCookie('id_token');

    if (!idToken) {
        logger.debug('could not convertIfc since no user is signed in');
        return;
    }

    const authorizationValue = `Bearer ${idToken}`;
    logger.debug(authorizationValue);

    this.isLoading = true;
    this.loadingText = 'preparing conversion...';

    const invokeS3ToEfsResponse = await invokeS3ToEfs({
        objectKey,
        authorizationValue,
    });
    logger.debug(invokeS3ToEfsResponse);

    this.loadingText = 'converting file...';
    const invokeIfcConvertResponse = await invokeIfcConvert({
        objectKey,
        authorizationValue,
    });
    logger.debug(invokeIfcConvertResponse);

    this.loadingText = 'finalizing conversion...';
    const invokeEfsToS3Response = await invokeEfsToS3({
        objectKey,
        authorizationValue,
    });
    logger.debug(invokeEfsToS3Response);

    await this.buildTable();

    return {};
}

async function deleteS3Object(objectKey) {
    logger.info(`deleteS3Object ${objectKey}`);

    this.loadingText = 'deleting file...';
    this.isLoading = true;
    this.preventSelectOnce = true;

    await invokeS3Delete(objectKey);

    this.buildTable();
}

function isIfc(fileName) {
    return fileName.endsWith('ifc');
}

function isGlb(fileName) {
    return fileName.endsWith('glb');
}

function data() {
    return {
        header: {
            title: 'Files',
            type: 'files',
        },
        yo: true,
        items: [
            { message: 'Foo' },
            { message: 'Bar' },
            { message: 'miau' },
            { message: 'tick' },
        ],
        isLoading: false,
        s3Objects: [],
        selectedFile: {
            index: -1,
            selected: false,
        },
        preventSelectOnce: false,
        loadingText: '',
    };
}

function setHeader() {
    this.$root.$emit('set_header_title', this.header);
}

export default {
    data,

    mounted() {
        logger.info('mounted');

        this.setHeader();

        const idToken = readCookie('id_token');

        if (!idToken) {
            logger.debug('not signed in');
        }

        logger.debug('signed in');

        this.buildTable();
    },

    methods: {
        buildTable,
        calculateCompliance,
        calculateSemantics,
        convertIfc,
        deleteS3Object,
        isFileSelected,
        isGlb,
        isIfc,
        render,
        selectFile,
        setHeader,
    },
};
</script>

<style scoped>
#files {
}

#files_table {
    width: 100%;
    table-layout: fixed;
}

td.files_row_active {
    background: var(--main_color_bg_light);
    padding-bottom: 20px;
}

td.files_row_hover:hover {
    background: var(--main_color_bg_light);
}

.file_name {
    color: var(--main_color_fg_dark);
    font-weight: bold;
    text-align: center;
    font-size: min(7vw, 4vh);

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
