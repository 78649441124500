const axios = require('axios');
const logger = require('../createLogger')('invokeEfsToS3.js');

module.exports = async function({ objectKey, authorizationValue }) {
    logger.info(`copying from efs to s3: ${objectKey}`);

    if (!objectKey) {
        logger.warn('objectKey was falsy, request not executed');
        return null;
    }

    const file = objectKey.replace('.ifc', '.glb');

    const response = await axios({
        method: 'POST',
        url: `https://${process.env.VUE_APP_HOST_PUBLIC_API}/api/v1/efs/to/s3?file=${file}`,
        headers: {
            'Content-type': 'application/json',
            Authorization: authorizationValue,
        },
    });

    return response;
};
