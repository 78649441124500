const axios = require('axios');

const readCookie = require('../readCookie');
const logger = require('../createLogger')('invokeS3List.js');

module.exports = async function() {
    logger.info('invoking s3_list');

    const idToken = readCookie('id_token');
    if (!idToken) {
        logger.info('could not list files since no user is signed in');
        return;
    }

    const authorizationValue = `Bearer ${idToken}`;

    const response = await axios({
        method: 'GET',
        url: `https://${process.env.VUE_APP_HOST_PUBLIC_API}/api/v1/s3/userfiles/list`,
        headers: {
            'Content-type': 'application/json',
            Authorization: authorizationValue,
        },
    });

    const { objectList } = response.data;

    if (!objectList) {
        logger.error('error when trying to list files');
        return;
    }

    logger.info('sucessfully retrieved file list');
    return objectList;
};
