const axios = require('axios');
const logger = require('../createLogger')('invokeIfcConvert.js');

module.exports = async function({ objectKey, authorizationValue }) {
    logger.info(`converting ifc: ${objectKey}`);

    if (!objectKey) {
        logger.warn('objectKey was falsy, request not executed');
        return null;
    }

    const response = await axios({
        method: 'POST',
        url: `https://${process.env.VUE_APP_HOST_PUBLIC_API}/api/v1/ifc/convert?file=${objectKey}`,
        headers: {
            'Content-type': 'application/json',
            Authorization: authorizationValue,
        },
    });

    return response;
};
