const axios = require('axios');

const readCookie = require('../readCookie');
const logger = require('../createLogger')('invokeS3Delete.js');

module.exports = async function(objectKey) {
    logger.info('invoking s3_delete');

    const idToken = readCookie('id_token');
    if (!idToken) {
        logger.info('could not delete file since no user is signed in');
        return;
    }

    const authorizationValue = `Bearer ${idToken}`;

    const response = await axios({
        method: 'DELETE',
        url: `https://${process.env.VUE_APP_HOST_PUBLIC_API}/api/v1/s3/userfiles?file=${objectKey}`,
        headers: {
            'Content-type': 'application/json',
            Authorization: authorizationValue,
        },
    });

    const { data } = response;

    if (!data) {
        logger.error('error when trying to delete file');
        return;
    }

    logger.info('sucessfully deleted file list');
    return data;
};
